<template>
  <div>
    <v-container class="my-5">
      <v-layout row wrap>
        <v-flex xs6 md3 lg2 v-for="team in sortedTeams" :key="team.id">
          <v-card flat class="text-center my-2">
            <v-icon x-large @click="teamDetail(team)">
              {{ team.icon }}
            </v-icon>
            <p class="mt-3 mb-0">
              {{ team.name_short }}
            </p>
            <span class="text-date">{{ team.date }}</span>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "TeamsListSearch",
  props: {
    teams: { type: Array, require: true },
  },

  computed: {
    sortedTeams() {
      return [...this.teams].sort((a, b) => {
        return new Date(b.startDate) - new Date(a.startDate);
        // const dateA = a.startDate ? new Date(a.startDate) : 0;
        // const dateB = b.startDate ? new Date(b.startDate) : 0;
        // return dateB - dateA;
      });
    },
  },

  data: () => ({}),
  methods: {
    teamDetail(team) {
      //   console.log("click, click, click", team);
      this.$router.push(`/equipos/${team.id}`);
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
p {
  font-family: "Spartan", sans-serif;
  font-weight: 800;
  font-size: 0.7rem;
  text-transform: uppercase;
}
.text-date {
  font-family: "Spartan", sans-serif;
  font-size: 0.55rem;
  color: gray;
}

.v-icon {
  color: black !important;
}

.v-icon:hover {
  color: #1b5e20 !important;
}
</style>
