<template>
  <div>
    <!-- Search -->
    <v-container>
      <!-- The form -->
      <form class="example">
        <input
          type="text"
          placeholder="Busca un equipo por nombre o fecha…"
          name="search"
          :value="$store.state.searchm.search"
          @input="$store.dispatch('searchm/setSearch', $event.target.value)"
        />
      </form>
    </v-container>

    <!-- teamlist -->
    <TeamListSearch
      v-if="$store.getters['searchm/searchedTeams'].length > 0"
      :teams="$store.getters['searchm/searchedTeams']"
    />
    <div v-else>
      <div class="text-search my-5 text-center">No hay resultados</div>
    </div>
  </div>
</template>

<script>
import TeamListSearch from "../components/TeamListSearch.vue";
export default {
  name: "TeamSearch",
  components: {
    TeamListSearch,
  },
  mounted() {
    this.$store.dispatch("searchm/AllTeams");
  },
  data: () => ({}),
  methods: {},
};
</script>

<style scoped>
/* Style the search field */
form.example input[type="text"] {
  padding: 10px;
  font-size: 0.8rem;
  border-bottom: 1px solid grey;
  float: left;
  width: 100%;
  font-family: "Spartan", sans-serif;
  font-weight: 600;
}

.text-search {
  font-family: "Spartan", sans-serif;
  font-weight: 600;
}

/* Clear floats */
form.example::after {
  content: "";
  clear: both;
  display: table;
}
</style>
